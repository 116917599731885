import * as React from "react"
import strings from "../../i18n"

import lightbulb from "../../images/lightbulb.svg"
import gemini from "../../images/gemini.svg"

import checkmark from "../../images/checkmark.svg"

import "./about.scss"

const About = () => {
  return (
    <div className="about section">
      <div className="container">
        <h1>{strings.about_us}</h1>
        <div className="cols">
          <div className="primary">
            <div className="mission">
              <div className="left">
                <span className="body1">{strings.our_mission}</span>
                <h3>{strings.we_saw_a_problem}</h3>
                <img src={lightbulb}/>
              </div>
              <div className="right body2">{strings.mission_statement}</div>
            </div>
            <div className="team">
              <div className="tag body1">{strings.our_team}</div>
              <h3>{strings.founded_and_backed_by_industry_experts}</h3>
              <div className="body2">{strings.team_statement}</div>
              <div className="info">
                <div className="left">
                  <div className="body1">{strings.homes_per_year}</div>
                  <div className="body2">{strings.built_by_our_network}</div>
                </div>
                <img src={gemini}/>
              </div>
            </div>
          </div>
          <div className="secondary">
            <div className="tag body2">{strings.our_values}</div>
            <h4>{strings.your_wins_are_our_wins}</h4>
            <p className="body2">{strings.aligned_incentives}</p>
            <div className="divider"></div>
            <h4>{strings.high_quality_standards}</h4>
            <p className="body2">{strings.partned_with_best_in_class}</p>
            <div className="standard"><img src={checkmark}/><div className="body2">{strings.two_year_warranty}</div></div>
            <div className="standard"><img src={checkmark}/><div className="body2">{strings.award_winning_designs}</div></div>
            <div className="standard"><img src={checkmark}/><div className="body2">{strings.high_quality_construction_and_finishes}</div></div>
            <div className="divider"></div>
            <h4>{strings.investing_in_community}</h4>
            <p className="body2">{strings.homes_are_places_to_live}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
