import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"

import Button from "../button/button"

import "./getintouch.scss"

const GetInTouch = () => {
  return (
    <div className="getintouch section">
      <div className="container">
        <h2>{strings.get_in_touch}</h2>
        <h4>{strings.get_in_touch_questions}</h4>
        <Button size="large" path="/contact">{strings.contact_us} <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 -8.74228e-07C16.0228 -3.91405e-07 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.499998 15.5228 0.499999 10C0.506061 4.47966 4.97967 0.0060602 10.5 -8.74228e-07ZM10.5 18C14.8946 17.9989 18.4655 14.4531 18.4978 10.0587C18.53 5.66417 15.0114 2.06644 10.6173 2.00089C6.22319 1.93534 2.59888 5.42651 2.5 9.82L2.5 8.037L2.5 10C2.50496 14.4162 6.08378 17.995 10.5 18ZM10.5 5L15.5 10L10.5 15L9.09 13.59L11.67 11L5.5 11L5.5 9L11.67 9L9.09 6.41L10.5 5Z" fill="#FCFBF8"/>
</svg></Button>
      </div>
    </div>
  )
}

export default GetInTouch