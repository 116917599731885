import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import GetInTouch from "../components/getintouch/getintouch"
import About from "../components/about/about"
import SEO from "../components/seo/seo"

const AboutPage = () => {
  return (
    <Layout chat={true}>
        <About/>
        <GetInTouch/>
    </Layout>
  )
}

export default AboutPage

export const Head: HeadFC = () => <SEO title="About HomeFounder"/>
